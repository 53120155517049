import React from 'react'

const About = () => {
  return (
    <div className="outerbox">
        <div>CREDITS</div>
        <div>SEACAT BY: LUKA REJEC</div>
        <div>SRDCAT BY: MATTHEW BOZIN</div>
        <div>FRONT PAGE ART BY: LUKA REJEC</div>
        <hr />
        <div>DEPLOYED WITH NETLIFY</div>
    </div>
  )
}

export default About