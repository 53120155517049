import React from "react";

const Flavor = (props) => {
  return (
    <div className="pbottom8px">
      <i>{props.flavor}</i>
    </div>
  );
};

export default Flavor;
